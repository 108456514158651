<!-- 生成二维码 -->
<script lang="ts" setup>
import vueQr from 'vue-qr/src/packages/vue-qr.vue'
import payLogo from '@/assets/images/douyin.png'

const state = reactive({
  title: '',
  isShowDialog: false,
  share_url: '',
  logoSrc: payLogo,
})

// 打开弹窗
const openDialog = (play_url: string) => {
  state.share_url = play_url
  state.isShowDialog = true
}

// 二维码生成回调
function getCode(imgCode: any) {
  // console.log('生成二维码 ', imgCode)
}

// 关闭弹窗
const closeDialog = () => {
  state.isShowDialog = false
}
// 取消
const onCancel = () => {
  closeDialog()
}

const onClose = () => {
}
// 页面加载时
onMounted(async () => {
})

// 暴露变量
defineExpose({
  openDialog,
})
</script>

<template>
  <el-dialog
    v-model="state.isShowDialog"
    :title="state.title"
    width="400px"
    top="5px"
    draggable append-to-body destroy-on-close
    @close="onClose"
  >
    <div class="call-rate">
      <h3>请使用抖音APP扫码进行视频转发</h3>
      <div class="qr">
        <vue-qr
          :text="state.share_url"
          :size="150"
          :margin="10"
          color-dark="#000"
          color-light="#fff"
          background-color="#fff"
          :logo-src="state.logoSrc"
          :logo-margin="3"
          :correct-level="3"
          :callback="getCode"
        />
      </div>
    </div>
  </el-dialog>
</template>

<style lang="scss" scoped>
.call-rate {
    display: flex;
    flex-direction: column;
    align-items: center;

    .qr {
        cursor: pointer;
    }
}
</style>
